import './ComingSoon.css';

function App() {

  return (
    <div className="bgimg">
      <div className="coming-soon">
        <h1 className="title">Elena Robbins Jewelery</h1>
        <hr/>
        <p>Coming Soon</p>
      </div>
    </div>
  );
}

export default App;
